import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import PageHeader from "../components/elements/PageHeader";
import Container from "../components/layout/Container";

const Careers = () => (
  <Index>
    <Seo title="Careers" />
    <PageHeader extraClasses="auto-height">
      <h1>Join our Team</h1>
    </PageHeader>
    <Container extraContainerClasses="align-center space-out">
      <h2 className="text-blue">Careers</h2>
      <p>To apply for an open position, please email your resume and cover letter to: <a href="mailto:resumes@fffenterprises.com">resumes@fffenterprises.com</a>.</p>
      <p>
        <a href="http://www.fffenterprises.com/site/applicant-privacy-policy.html">
          CCPA Employee Applicant Privacy Notice
        </a>
      </p>
    </Container>
    <Container extraContainerClasses="align-center space-out">
      <h2 className="text-blue">Job Opportunities</h2>
      <p>
        <a href="/careers/senior-vice-president-operations">Senior Vice President, Operations</a>
      </p>
    </Container>
  </Index>
)

export default Careers
